.Wrapper{
	/*background-color: var(--color__white);*/
	text-decoration: none;
	cursor: pointer;
}

	.Wrapper:global > h2{
		font-size: 2em;
	    font-weight: normal;
	    margin-bottom: .5em;
	    line-height: 1.25;
	}

	.Wrapper:global > h2 > span{
		font-family: var(--fontfamily__normal);
		font-size: .75em;
		color: var(--color__white-light);
		background-color: var(--color__green);
		padding: .25em .5em;
	}

	.Wrapper:global:hover > h2 > span{
		background-color: var(--color__red);
	}

	.Wrapper:global > p{
		font-size: 1.25em;
		color: var(--color__gray);
		line-height: 1.5;
		margin-top: 0;
	}