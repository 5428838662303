/* Layout */

header, 
main, 
footer{
	max-width: var(--screen__xl);
	padding: 1rem;
	margin: auto;
	box-sizing: border-box;
}

.wrapper{
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center 58px;	/* 58px header clear */
	transition: all .3s ease-out;
}
	/* keep non-home wrappers contain*/
	.wrapper:not(.wrapper-home){
		background-size: contain !important;
	}


header{
	width: 100%;
	min-width: 100%;
	z-index: 996;
	transition: all .2s ease-in;
	background-color: var(--color__white-light);
}
	/* sticky header */
	.isSticky header{
		position: fixed;
		background-color: var(--color__white-light);
		box-shadow: 0 2px 10px -5px var(--color__gray);
	}

	header div{
		display: flex;
		max-width: var(--screen__xl);
		margin: auto;
	}

	header div .logo{
		flex:0 0 30%;
	}

		header div .logo img{
			max-height: 32px;
			width: auto;
			max-width: 100%;
		}

	header div nav{
		flex:0 1 70%;
	}


footer{
	min-height: 74px; /* to maintain full-VH in <main>*/
	max-height: 74px;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

	.copyright-text{
		margin-top: .75em;
		font-size: var(--font__small);
		color: var(--color__gray-light);
	}


@media screen and (orientation: portrait){
	.wrapper{
		background-size: cover;
		background-position: center 58px;
	}
}

	@media screen and (orientation: portrait) and (min-width: 768px){
		.wrapper{
			background-size: cover;
			background-position: 80% 58px;
		}
	}

@media screen and (min-width: 1900px){
	.wrapper{
		background-size: cover;		/* stretch for higher screens */
	}
}