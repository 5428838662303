.Tag{
	font-size: 0.75em;
	text-transform: capitalize;
	color: var(--color__gray-dark);
	list-style: none;
	display: inline-block;
	background-color: var(--color__gray);
	padding: .15em .5em;
	border-radius: .25em;
	margin: .15em;
}

	.TagIncluded{
		background-color: var(--color__green);
	}
	.TagNotincluded{
		background-color: var(--color__gray-light);
	}