.Modal {
	font-size: 0.85rem;
	box-sizing: border-box;
	display: block;
	max-width: 96%;
	border-radius: 1em;
	padding: 2em;
	margin-bottom: 2em;
	z-index: 1000;

	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -46%);
	
	background-color: white;
	background-repeat: no-repeat;
	background-position: bottom left;
	background-size: 7em;

	box-shadow: 0 3px 10px 3px var(--color__gray-dark);
}


.Header:global > .page-title{
	margin: 0;
}

.BtnClose {
	position: absolute;
	right: 0.5em;
	top: 1em;
	opacity: 0.3;
	display: block;
	width: 3em;
	height: 3em;
}
	.BtnClose:hover {
	  opacity: 1;
	  cursor: pointer;
	}
	.BtnClose:before, .BtnClose:after {
	  position: absolute;
	  left: 15px;
	  content: ' ';
	  height: 2em;
	  width: 1px;
	  background-color: #333;
	}
	.BtnClose:before {
	  transform: rotate(45deg);
	}
	.BtnClose:after {
	  transform: rotate(-45deg);
	}

.Body {
    padding: .5em 0 3em;
}


.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  
  background: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 992px) and (orientation: landscape ){
	.Modal {
		top: 2%;
		transform: translate(-50%, 0);
	}
}

@media screen and (min-width: 768px){
	
	.Modal {
		font-size: 1rem;
		max-width: 100%;
		/*max-height: 100%;*/
		padding: 2em 4em 3em;
		background-size: 9em;
		/* top: 50%;
		transform: translate(-50%, -50%); */
	}

	.Header:global > .page-title{
		margin: inherit;
	}
	
		.BtnClose:before, .BtnClose:after {
		  height: 3em;
		}

}