.FactList{
	margin: 1em 0;
}

/* data */
.FactList:global > span{
	margin-right: 1em;
}

/* icon */
.FactList:global > span > svg{
	vertical-align: middle;
	color: var(--color__green);
}