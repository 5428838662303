@import url(https://fonts.googleapis.com/css2?family=Grand+Hotel&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap);
/* variables */
:root {

	/*COLORS*/
	--color__green-dark3:#4C5832;
	--color__green-dark2:#61C790;
	--color__green-dark:#80B636;
	--color__green:#8DC63F;
	--color__green-light:#B1AB52;
	--color__green-light2:#CAD792;
	--color__green-light3:#F5FCF9;

	--color__red-dark: #f44336;
	--color__red: #FE5E5A;
	--color__red-light: #fb7471;
	--color__red-light2: #f7dbd7;
	--color__yellow: #fff3cd;
	--color__yellow-dark: #ffeeba;
	--color__white: #F7F7F5;
	--color__white-light: #FFFFFF;
	--color__gray-dark: #505057;
	--color__gray: #8D8D99;
	--color__gray-light: #CCCCCC;
	--color__gray-light2: #F2F2F2;
	--color__blue-dark: #0c5460;
    --color__blue: #bee5eb;;
    --color__blue-light: #d1ecf1;
	


	/*// TYPOGRAPHY*/
	--fontfamily__normal: 'Merriweather', serif;
	--fontfamily__header: 'Grand Hotel', cursive;;

	--font__xs: 8px;
	--font__small: 12px;
	--font__normal: 14px;
	--font__large: 20px;
	--font__xl: 32px;
	--font__xxl: 60px;


	/*// BREAK POINT*/
	--screen__xs: 0;
	--screen__sm: 576px;
	--screen__md: 768px;
	--screen__lg: 992px;
	--screen__xl: 1200px;
	--screen__xxl: 1600px;

}

*{
	box-sizing: border-box;
}
	svg{
		box-sizing: content-box;
	}

/* make scrollbar appear by default */
/* to avoid layout jumps when scroll conent */
html { 
  	scroll-behavior: smooth;
	overflow-y: scroll; 
} 

body {
	margin: 0;
	font-family: 'Merriweather', serif;
	font-family: var(--fontfamily__normal);
	font-size: 14px;
	font-size: var(--font__normal);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/*background: linear-gradient(to bottom,  #ffffff 58px, #f5fcf9 58px);	*/
	background-color: #F5FCF9;
	background-color: var(--color__green-light3);
}

body, a, p{
	color: #505057;
	color: var(--color__gray-dark);
}

h1, h2, h3{
	font-family: 'Grand Hotel', cursive;
	font-family: var(--fontfamily__header);
	color: #505057;
	color: var(--color__gray-dark);
}

strong{
	font-weight: 700;
}
.text-primary{
	color: #80B636;
	color: var(--color__green-dark);
}
.text-secondary{
	color: #505057;
	color: var(--color__gray-dark);
}

#root{
	width: 100%;
}


.input-wrapper{
	position: relative;
	display: flex; 		/* to swap label-left and right */
	flex-wrap: wrap; 	/* to display errortext below */
    margin: .5em 0;
}

/* input error message */
.error-text{
	display: none;
	padding: .5em 0 0;
    color: #FE5E5A;
    color: var(--color__red);
    font-size: .75em;
}
	.error-text.active{
		display: block;
	}
	
/* text variations */
.load-error-small {
    font-size: .75em;
    text-transform: uppercase;
    margin: .25em;
    color: #FE5E5A;
    color: var(--color__red);
    vertical-align: text-top;
}


label {
    display: inline-block;
    /*margin-bottom: .5rem;*/
    /*margin-bottom: 1em;*/
}
	label.label-left {
	    display: inline-block;
	    vertical-align: middle;
	    margin-right: .25em;
	}
		label.label-left + input {
		    display: inline-block;
		}

	label.label-right {
	    display: inline;
	    vertical-align: middle;
	    order: 2; /* swap label with input */
	    margin-left: .25em;
	}
		label.label-right + input {
		    display: inline-block;
		}


input {
	font-family: 'Merriweather', serif;
	font-family: var(--fontfamily__normal);
    color: #8D8D99;
    color: var(--color__gray);
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5;
    background-color: #F7F7F5;
    background-color: var(--color__white);
	
	display: block;
    width: 100%;
    max-width: 100%;
    padding: .5em .75em;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25em;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
} 

	input::-webkit-input-placeholder { 
	  color: #CCCCCC; 
	  color: var(--color__gray-light);
	  opacity: 1;
	} 

	input:-ms-input-placeholder { 
	  color: #CCCCCC; 
	  color: var(--color__gray-light);
	  opacity: 1;
	} 

	input::-ms-input-placeholder { 
	  color: #CCCCCC; 
	  color: var(--color__gray-light);
	  opacity: 1;
	} 

	input::placeholder { 
	  color: #CCCCCC; 
	  color: var(--color__gray-light);
	  opacity: 1;
	}

	input[type=checkbox],
	input[type=radio]{
    	width: inherit;
		height: inherit;
	}
	
	button,
	.btn{
	    font-size: 1em;
	    font-weight: 500;
	    line-height: 1.5;
	    letter-spacing: .05em;
	    text-decoration: none;
	    text-transform: uppercase;
	    text-align: center;
	    color: #FFFFFF;
	    color: var(--color__white-light);
	    background-color: #80B636;
	    background-color: var(--color__green-dark);
	    
	    vertical-align: middle;
		display: inline-block;
	    border: 0;
	    -webkit-user-select: none;
	       -moz-user-select: none;
	        -ms-user-select: none;
	            user-select: none;
	    padding: .375em 1em;
	    margin-top: .375em;
	    border-radius: .25em;
	    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	}

	button.sm,
	.btn.sm{
	    font-size: .9em;
	    font-weight: 300;
	    line-height: 1;
	    letter-spacing: .05em;
	    padding: .5em 1em;
	    border-radius: .25em;
	}

	button.secondary,
	.btn-secondary{
	    background-color: #8D8D99;
	    background-color: var(--color__gray);
	}
	
		button:not(:last-child),
		.btn:not(:last-child){
	    	margin-right: .5em;
		}

		button:hover,
		.btn:hover{
			cursor: pointer;
		    background-color: #4C5832;
		    background-color: var(--color__green-dark3);
		}



.page-title{
	font-size: 2.15em;
	margin-top: 0;
	line-height: .85;
	text-align: center;
	font-weight: 500;
	letter-spacing: .05em;
	color: #505057;
	color: var(--color__gray-dark);
}
	.page-title > span{
		color: #8DC63F;
		color: var(--color__green);
	}


.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}


/* Tooltip container */
.tooltip {
  position: relative;
}

	/* Tooltip text */
	.tooltip .tooltiptext {
	  /*visibility: hidden;*/
	  width: 200px;
	  background-color: #505057;
	  background-color: var(--color__gray-dark);
	  color: #FFFFFF;
	  color: var(--color__white-light);
	  text-align: center;
	  padding: 1em;
	  border-radius: .5em;
	 
	  position: absolute;
	  z-index: 1;
	  box-shadow: 0px 1px 3px 0px #505057;
	  box-shadow: 0px 1px 3px 0px var(--color__gray-dark);
	  
	  transition: opacity .4s ease-in;	
	}
		/* arrow */
		.tooltiptext:before{
		    content:'';
		    display:block;
		    width:0;
		    height:0;
		    position:absolute;
		    border-top: 15px solid #505057;
		    border-top: 15px solid var(--color__gray-dark);
		    border-right:15px solid transparent;
		    border-left:15px solid transparent;
		    left:45%;
		    bottom:-10px;
		}


/* LOADING ICON */
.loading-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 120px;
	text-align: center;
    -webkit-animation-iteration-count: 0;
            animation-iteration-count: 0;
}
	.loading-wrapper img {
		-webkit-animation: roll 1.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite;
		        animation: roll 1.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite;	
	}

	.loading-wrapper span {
		margin: 0 0 0 .75em;
		-webkit-animation: rolltext 1.5s infinite;
		        animation: rolltext 1.5s infinite;	
	}

	@-webkit-keyframes roll {
	  0% { transform: translateY(-10px) rotateX(0); opacity: 1; }
	  40% { transform: translateY(0) rotate(-5deg); opacity: .8; }
	  60% { transform: translateY(0) rotate(5deg); opacity: .8; }
	  100% { transform: translateY(-10px) rotateX(0); opacity: 1; }
	}

	@keyframes roll {
	  0% { transform: translateY(-10px) rotateX(0); opacity: 1; }
	  40% { transform: translateY(0) rotate(-5deg); opacity: .8; }
	  60% { transform: translateY(0) rotate(5deg); opacity: .8; }
	  100% { transform: translateY(-10px) rotateX(0); opacity: 1; }
	}

	@-webkit-keyframes rolltext {
	  0% { letter-spacing: inherit; font-weight: normal; }
	  40% { letter-spacing: 2px; font-weight: normal; }
	  60% { letter-spacing: 4px; font-weight: 600; }
	  100% { letter-spacing: inherit; font-weight: normal; }
	}

	@keyframes rolltext {
	  0% { letter-spacing: inherit; font-weight: normal; }
	  40% { letter-spacing: 2px; font-weight: normal; }
	  60% { letter-spacing: 4px; font-weight: 600; }
	  100% { letter-spacing: inherit; font-weight: normal; }
	}

.fa-spinner{
	-webkit-animation: spin 1.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite;
	        animation: spin 1.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite;	
}
	@-webkit-keyframes spin {
	  0% { transform: rotate(0deg); }
	  100% { transform: rotate(360deg); }
	}
	@keyframes spin {
	  0% { transform: rotate(0deg); }
	  100% { transform: rotate(360deg); }
	}

/* global styles for all recipe lists */
/* lists */
.recipeListWrapper{
	box-sizing: border-box;
    display: flex;
    align-items: stretch;
	flex-wrap: wrap;
    padding: 1em;
    align-self: start; /* items < 10 will stretch abnormally */
}
	/* no results */
	.recipeListWrapper.no-results{
		align-items: center;
	    justify-content: start;
	    padding: 2em 4em;
	}

	

@media screen and (min-width: 576px ){
	.page-title{
		font-size: 2.5em;
		margin-top: inherit;
	}
}

@media screen and (min-width: 768px ){
	.page-title{
		margin: .5em;
	}
}
.MainMenu_Nav__1upJg {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: relative;
}

.MainMenu_Nav__1upJg > .menu{
	flex: 0 1 94%;
	/*display: none;*/
	justify-content: flex-end;
}

.MainMenu_Nav__1upJg > .menu > a{
	padding: 0 .5rem;
	border-right: 1px solid var(--color__gray);
	text-decoration: none;
}

	.MainMenu_Nav__1upJg > .menu > a:hover,
	.MainMenu_Nav__1upJg > .menu > a.active{
		color: var(--color__green);
	}

@media screen and (min-width: 768px ) {
	.MainMenu_Nav__1upJg > .menu{
		display: flex;
	}		
	
	.MainMenu_Nav__1upJg > .menu > a{
		padding: 0 1rem;
	}

}
/* menu items */
	/* main menu icon */
.Menu_MenuItemIcon__3CJg_.menu-main{
	display: flex;
	font-size: 1.5em;
	vertical-align: middle;	
}
	/* main menu label */
.Menu_MenuItemLabel__2mKsn.menu-main{
	display: none;	
}
	/* main menu icon */
.Menu_MenuItemIcon__3CJg_.menu-login{
	margin-right: .5em;
}



@media screen and (min-width: 768px){
	.Menu_MenuItemIcon__3CJg_.menu-main{
		display: none;	
	}
	.Menu_MenuItemLabel__2mKsn.menu-main{
		display: inherit;	
	}	
}
.LoginMenu_Nav__2kJZZ {
	z-index: 997;
	max-width: 200px;
	flex-direction: column;
	display: none;
	position: absolute;
	top: 30px;
	right: -1em;
	padding: 1.5rem .25rem;
	background-color: rgba(255, 255, 255, 0.9);
	box-shadow: 0 10px 10px -5px var(--color__gray);
}

/* greeting */
.LoginMenu_Nav__2kJZZ > .user-name{
	font-size: .95em;
    color: var(--color__gray);
    text-align: center;
    border-bottom: 1px solid var(--color__gray-light2);
    padding-bottom: .5em;
    margin-bottom: .25em;
}

.LoginMenu_Nav__2kJZZ > .menu{
	display: flex;
	flex-direction: column;
}

.LoginMenu_Nav__2kJZZ > .menu > a{
	padding: 0 1rem;
	line-height: 2.5;
	border-bottom: 1px solid var(--color__white-light);
	text-decoration: none;
}
	.LoginMenu_Nav__2kJZZ > .menu > a:hover{
		color: var(--color__green);
		border-bottom: 1px solid var(--color__white);
	}
	
	.LoginMenu_Nav__2kJZZ > .menu > a:last-child{
		border-bottom: 0;
	}

	.LoginMenu_Nav__2kJZZ > .menu > a > svg{
		color: var(--color__gray);
		margin-right: .5rem;
		vertical-align: middle;
	}
		.LoginMenu_Nav__2kJZZ > .menu > a:hover > svg{
			color: var(--color__gray-dark);
		}


/* login icon */
.LoginMenu_LoginIcon__N_Bqs{
	z-index: 998;
	margin-left: 1rem;
	cursor: pointer;
	white-space: nowrap;
}
	.LoginMenu_LoginIcon__N_Bqs > svg{
		position: relative; /* to apply z-index*/	
		z-index: 998;
		font-size: 1.65em;
    	line-height: 0;
    	vertical-align: middle;
    }

	.LoginMenu_LoginIcon__N_Bqs > svg:hover{
		color: var(--color__green);
	}

/* all page wrappers */
main{
	box-sizing: border-box;
	padding: 0;
	min-height: calc(100vh - 132px); /* header, footer == 58, 74px each*/
}

main > section{
	box-sizing: border-box;
}
.withModal_Modal__KPYj8 {
	font-size: 0.85rem;
	box-sizing: border-box;
	display: block;
	max-width: 96%;
	border-radius: 1em;
	padding: 2em;
	margin-bottom: 2em;
	z-index: 1000;

	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -46%);
	
	background-color: white;
	background-repeat: no-repeat;
	background-position: bottom left;
	background-size: 7em;

	box-shadow: 0 3px 10px 3px var(--color__gray-dark);
}


.withModal_Header__3Yq9L > .page-title{
	margin: 0;
}

.withModal_BtnClose__38paK {
	position: absolute;
	right: 0.5em;
	top: 1em;
	opacity: 0.3;
	display: block;
	width: 3em;
	height: 3em;
}
	.withModal_BtnClose__38paK:hover {
	  opacity: 1;
	  cursor: pointer;
	}
	.withModal_BtnClose__38paK:before, .withModal_BtnClose__38paK:after {
	  position: absolute;
	  left: 15px;
	  content: ' ';
	  height: 2em;
	  width: 1px;
	  background-color: #333;
	}
	.withModal_BtnClose__38paK:before {
	  transform: rotate(45deg);
	}
	.withModal_BtnClose__38paK:after {
	  transform: rotate(-45deg);
	}

.withModal_Body__2iwc8 {
    padding: .5em 0 3em;
}


.withModal_Overlay__2qeFW {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  
  background: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 992px) and (orientation: landscape ){
	.withModal_Modal__KPYj8 {
		top: 2%;
		transform: translate(-50%, 0);
	}
}

@media screen and (min-width: 768px){
	
	.withModal_Modal__KPYj8 {
		font-size: 1rem;
		max-width: 100%;
		/*max-height: 100%;*/
		padding: 2em 4em 3em;
		background-size: 9em;
		/* top: 50%;
		transform: translate(-50%, -50%); */
	}

	.withModal_Header__3Yq9L > .page-title{
		margin: inherit;
	}
	
		.withModal_BtnClose__38paK:before, .withModal_BtnClose__38paK:after {
		  height: 3em;
		}

}
.Login_BtnWrapper__35bS1{

}

.Login_SignupLoginLink__haIBa{
	display: block;
	margin-top: 1em;
	color: var(--color__gray);
	font-size: .85em;
}
/* notice */
.Alert_Alert__2L8UU {
    position: relative;
    padding: .75em 2em .75em 1em;
    margin: 1rem auto;
    border: 1px solid transparent;
    border-radius: .25rem;
    font-size: .85em;
	background-color: var(--color__gray-light);
	border-color: var(--color__gray);
}
	.Alert_Alert__2L8UU.vcenter {
		/*margin: 2%;*/
	}

	.Alert_Alert__2L8UU.error {
		background-color: var(--color__red-light);
		border-color: var(--color__red);
	}
	.Alert_Alert__2L8UU.success {
		background-color: var(--color__green);
		border-color: var(--color__green-dark);
	}
	.Alert_Alert__2L8UU.warning {
		background-color: var(--color__yellow);
		border-color: var(--color__yellow-dark);
	}
	.Alert_Alert__2L8UU.notice {
		background-color: var(--color__blue-light);
		border-color: var(--color__blue);
	}

	.Alert_Alert__2L8UU strong {
		text-transform: capitalize;	/* error type prop is lower case*/
	}

	.Alert_Alert__2L8UU svg {
		opacity: .25;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: .5em;
	}

	.Alert_Alert__2L8UU svg:hover {
		opacity: 1;
		cursor: pointer;
	}

	.Alert_Alert__2L8UU.regular svg{
		display: none;
	}

@media screen and (min-width: 768px){
	.Alert_Alert__2L8UU.vcenter {
		margin: 10%;
	}
}
.IntroPrompt_IntroWrapper__2u3XK{
	padding-top: .25em;
}

.IntroPrompt_List__1NOnm{
	list-style: none;
	margin: 1em 0 1.25em;
	padding: 0;
	display: flex;
	justify-content: space-between;
}
	.IntroPrompt_List__1NOnm li{
		flex: 0 0 33.333%;
	}
	.IntroPrompt_List__1NOnm li h3{
		font-size: 1.5em;
		letter-spacing: .05em;
		color: var(--color__green);
		text-align: center;
		font-weight: 400;
		margin: 0;
	}
	.IntroPrompt_List__1NOnm li img{
		max-width: 100%;
	}
	.IntroPrompt_List__1NOnm li p{
		font-size: .65em;
		color: var(--color__gray);
		margin: .25em 0;
		text-align: center;
	}

.IntroPrompt_ShowIntroCheckbox__1oY48{
	justify-content: center;
	margin-top: 1.25em;
	color: var(--color__gray);
}

.IntroPrompt_footerWrapper__1_DZ5{
	text-align: center;
}
/* home wrapper */
.home{
	font-size: .875rem; /* controls all included comps mesurments */
	box-sizing: border-box;
	display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}


.search,
.searchresults {
	transition: all .3s ease-out;
}

.search-enter{
	/*display: none;*/
	/* position: inherit; */
	opacity: 0;
	transform: translateY(-200%);
}
.search-entering{
	/* position: inherit; */
	opacity: 0.5;
	transform: translateY(-75%);
}
.search-entered{
	/* position: inherit; */
	opacity: 1;
	transform: translateY(0%);
}

.search-exit{
	/* position: inherit; */
	opacity: 1;
	transform: translateY(0%);
}
.search-exiting{
	/* position: inherit; */
	opacity: 0.5;
	transform: translateY(-75%);
}
.search-exited{
	/* position: absolute; */
	opacity: 0;
	transform: translateY(-200%);
	/*display: none;*/
}



.searchresults-enter{
	opacity: 0;
	transform: translateY(100%);
}
.searchresults-entering{
	opacity: 0.5;
	transform: translateY(75%);
}
.searchresults-entered{
	opacity: 1;
	transform: translateY(0%);
}

.searchresults-exit{
	opacity: 1;
	transform: translateY(0%);
}
.searchresults-exiting{
	opacity: 0.5;
	transform: translateY(75%);
}
.searchresults-exited{
	opacity: 0;
	transform: translateY(100%);
}




@media screen and (orientation: portrait){
	.home{ 
	    justify-content: center !important;
		padding-top: 10%;
	}
}

@media screen and (orientation: portrait) and (min-width: 576px){
	.home{ 
		padding-top: 30%;
	}
}

@media screen and (min-width: 992px){
	.home{ 
		font-size: 1rem; 
		padding-top: 8em; 			/* controls search-bar alignment with bg */
		justify-content: start;
	}
}

@media screen and (min-width: 1200px){
	.home{ 
		font-size: 1.15rem; 
		padding-top: 9em;
	}
}

@media screen and (min-width: 1400px){
	.home{ 
		font-size: 1.2rem; 
	}
}

@media screen and (min-width: 1600px){
	.home{ 
		font-size: 1.35rem; 
	}
}

@media screen and (min-width: 1900px){
	.home{ 
		font-size: 1.6rem; 
	}
}
/* size is controlled by parent font-size */

.SearchForm_SearchForm__Vh8VG{
	position: relative;
	min-width: 90%;
}

.SearchForm_SearchForm__Vh8VG .MuiAutocomplete-inputRoot{
	padding-right: 1em !important;
	background-color: var(--color__white-light);
}

.SearchForm_SearchForm__Vh8VG .MuiAutocomplete-input{
}

.SearchForm_SearchForm__Vh8VG .MuiInputLabel-root{
	color: var(--color__gray-light);
}
	.SearchForm_SearchForm__Vh8VG .MuiInputLabel-root.MuiInputLabel-shrink{
		color: var(--color__green);
	}

.SearchForm_SearchForm__Vh8VG .MuiAutocomplete-inputRoot .MuiOutlinedInput-notchedOutline{
	border: 1px solid var(--color__green);
}
	.SearchForm_SearchForm__Vh8VG .MuiAutocomplete-inputRoot:hover .MuiOutlinedInput-notchedOutline{
		border: 1px solid var(--color__green-dark);
	}
	.SearchForm_SearchForm__Vh8VG .MuiAutocomplete-inputRoot.Mui-focused .MuiOutlinedInput-notchedOutline{
		border: 1px solid var(--color__green-dark);
		/*border-color: var(--color__green-dark);*/
		box-shadow: 0px 0px 4px 0px var(--color__green);
	}

.SearchForm_SearchForm__Vh8VG .MuiAutocomplete-inputFocused{

}

.SearchForm_SearchForm__Vh8VG .MuiAutocomplete-tagSizeSmall{

}

.SearchForm_SearchForm__Vh8VG .MuiAutocomplete-endAdornment{

}

.SearchForm_SearchIcon__jIUJa{
	padding-left: 1em;
}
	.SearchForm_SearchIcon__jIUJa > button{
		background: none;
		padding: 0;
		margin:0;
    	color: var(--color__gray-light);
    	line-height: 0;
	}
	
	.SearchForm_SearchIcon__jIUJa > button > svg{
		font-size: 1.5em;
	}
	/* when search has values */
	.SearchForm_SearchForm__Vh8VG .MuiOutlinedInput-adornedStart .iconSearch svg{
    	color: var(--color__green);
	}


/* search by section */
/* .SearchTypeWrapper{
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--color__green);
	margin-top: .25em;
} */

.SearchForm_SearchTypeWrapper__26Rs6{
    position: relative;
	display: flex;
    justify-content: center;
    align-items: center;
    font-size: .9em;
    color: var(--color__green);
    background-color: var(--color__white-light);
    margin: auto;
    padding-top: .5em;
    padding-bottom: .5em;
    width: 100%;
    margin-top: -1px;
    border-radius: 0 0 .5em .5em;
    border: 1px solid;
    border-top: 0;
    border-color: var(--color__green);
    box-shadow: 0px 4px 3px 1px #0000002e
}
	.SearchForm_SearchTypeWrapper__26Rs6:before {
	    content: '';
	    width: 100%;
	    position: absolute;
	    background: transparent;
	    height: 1px;
	    top: -1px;
	    box-shadow: inset 0px 0px 1px 0px var(--color__green-light2);
	}

	.SearchForm_SearchTypeWrapper__26Rs6.SearchForm_NonPantryMatch__1HJWi input[type=radio]{
		display: none;
	}
		.SearchForm_SearchTypeWrapper__26Rs6.SearchForm_NonPantryMatch__1HJWi .input-wrapper:last-child{
			margin-left: 1.25em; 
		}

	.SearchForm_SearchTypeWrapper__26Rs6.SearchForm_NonPantryMatch__1HJWi label{
		cursor: pointer;
		font-size: .95em;
		display: flex;
		align-items: center;
	}

	.SearchForm_SearchTypeWrapper__26Rs6.SearchForm_NonPantryMatch__1HJWi label:before {
		  content: "";
		  width: 16px;
		  height: 16px;
		  border-radius: 8px;
		  margin-right: 8px; 
		  background-color: rgba(202, 215, 146, .68);
		  box-shadow: inset 0px 2px 3px 0px rgba(0, 0, 0, .175), 0px 1px 0px 0px rgba(255, 255, 255, .8);
	}
		.SearchForm_SearchTypeWrapper__26Rs6.SearchForm_NonPantryMatch__1HJWi label.isChecked:before {
		    content: "\2022";
		    color: #fff;
		    font-size: 30px;
		    text-align: center;
		    line-height: .5;
		}

/* ing filter */
/* .IngFilterWrapper{
	display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color__green);
    margin-top: .25em;
    background-color: rgba(245, 252, 249, .65);
    padding: .5em 1.25em;
    border-radius: .5em;
    max-width: 70%;
    margin: .5em auto 0;
} */
	.SearchForm_SearchTypeWrapper__26Rs6.SearchForm_PantryMatch__3IX1g{
		padding-left: 1.25em;
		padding-right: 1.25em;
		/*width: 60%;*/
	}
		.SearchForm_SearchTypeWrapper__26Rs6.SearchForm_PantryMatch__3IX1g > label{
			white-space: nowrap;
    		font-size: .95em;
		}
		.SearchForm_SearchTypeWrapper__26Rs6.SearchForm_PantryMatch__3IX1g > .MuiSlider-root{
    		margin-right: .5em;
    		margin-left: 1em;
		}
			.SearchForm_SearchTypeWrapper__26Rs6.SearchForm_PantryMatch__3IX1g .MuiSlider-rail {
				padding: .15em;
				border-radius: .5em;
				background-color: var(--color__green);
			}
			.SearchForm_SearchTypeWrapper__26Rs6.SearchForm_PantryMatch__3IX1g .MuiSlider-track {
				padding: .15em;
				border-radius: .5em;
				background-color: var(--color__green-dark);
				outline: 1px solid var(--color__green-light3);
			}
			.SearchForm_SearchTypeWrapper__26Rs6.SearchForm_PantryMatch__3IX1g .MuiSlider-thumb {
				height: 16px;
				width: 16px;
				background-color: var(--color__green-dark);
				border: 2px solid var(--color__green-light3);
			}
			.SearchForm_SearchTypeWrapper__26Rs6.SearchForm_PantryMatch__3IX1g .MuiSlider-valueLabel > span {
				background-color: var(--color__green-dark);

			}



@media screen and (min-width: 576px){
	.SearchForm_SearchForm__Vh8VG{
		min-width: 70%;
	}

	.SearchForm_SearchTypeWrapper__26Rs6{
	    width: 75%;
	}
}

@media screen and (min-width: 768px){
	.SearchForm_SearchForm__Vh8VG{
		min-width: 60%;
		max-width: 60%;
	}
	.SearchForm_Input__3fZqg{
	    font-size: 1.25em;
	}
}

@media screen and (min-width: 992px){
	.SearchForm_SearchTypeWrapper__26Rs6{
	    width: 55%;
	}
}

@media screen and (min-width: 1600px){
	.SearchForm_SearchForm__Vh8VG{
		min-width: 80%;
		max-width: 80%;
	}
	.SearchForm_SearchTypeWrapper__26Rs6 label{
		font-size: .75em;
	}
}



/* wrapper */
.QuickSearch_Wrapper__3ZIFR{
	box-sizing: border-box;
	width: 90%;
	margin: 1em 0 0;
	display: flex;
	flex-wrap: wrap;
}

/* Quick Search Item */
/* featured */
.QuickSearch_Featured__1xGxh{
    flex: 1 0 40%;
	display: none
}
.QuickSearch_SearchList__2uQdl{
    display: flex;
    flex: 1 0 60%;
    flex-wrap: wrap; 
}
	.QuickSearch_Item__TqGLH {
		box-sizing: border-box;
	    flex: 1 0 44%;
	    margin: 1%;
	    min-height: 6.5em;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    flex-direction: column;
	    background-color: var(--color__green);
	    color: var(--color__white);
		text-decoration: none;
	}

		.QuickSearch_Item__TqGLH svg {
			font-size: 2em;
		}

		.QuickSearch_Item__TqGLH p {
			font-size: .75em;
			letter-spacing: .05em;
			color: var(--color__white);
			margin: .5em 0 0;
		}
		
		.QuickSearch_Item__TqGLH:hover {
			cursor: pointer;
			background-color: var(--color__green-dark);
		}



	@media screen and (orientation: portrait){
		.QuickSearch_Wrapper__3ZIFR{
			font-size: 1.25em; /* increase icon size in potrait view */
		}

	}

	@media screen and (min-width: 576px ){
		.QuickSearch_Wrapper__3ZIFR{
			flex-wrap: nowrap;
			width: 75%;
		}
		.QuickSearch_Item__TqGLH {
			flex: 1 0 15%;
			margin: 0;
		}
	}

	@media screen and (min-width: 768px ){
		.QuickSearch_Wrapper__3ZIFR{
			margin: 2em 0 0;
			/* featured collapse below*/
			width: 60%;
			flex-direction: column;
		}
		.QuickSearch_Featured__1xGxh{
			box-sizing: border-box;
			font-size: .5em;
			flex: 1 0 40%;
			background-color: var(--color__white-light);
			padding: 0 3em;
			display: flex;
			align-items: center;
		}
	}

	@media screen and (min-width: 992px ){
		.QuickSearch_Wrapper__3ZIFR{
			width: 80%;
			flex-direction: row;
		}
	}

	@media screen and (min-width: 1600px ){
		.QuickSearch_Wrapper__3ZIFR{
			width: 100%;
			max-width: 100%;
		}
	}
.Featured_Wrapper__22WT_{
	/*background-color: var(--color__white);*/
	text-decoration: none;
	cursor: pointer;
}

	.Featured_Wrapper__22WT_ > h2{
		font-size: 2em;
	    font-weight: normal;
	    margin-bottom: .5em;
	    line-height: 1.25;
	}

	.Featured_Wrapper__22WT_ > h2 > span{
		font-family: var(--fontfamily__normal);
		font-size: .75em;
		color: var(--color__white-light);
		background-color: var(--color__green);
		padding: .25em .5em;
	}

	.Featured_Wrapper__22WT_:hover > h2 > span{
		background-color: var(--color__red);
	}

	.Featured_Wrapper__22WT_ > p{
		font-size: 1.25em;
		color: var(--color__gray);
		line-height: 1.5;
		margin-top: 0;
	}
 .Recipes_Recipes__10uqW{
	/*box-sizing: border-box;*/
	font-size: .875rem; 		/* controls all included comps mesurments */
	/*display: flex;
	align-items: center;
    flex-direction: column;*/
}

	/* Search */
	.Recipes_Search__2F0OR{
		margin: 1em 0 3em;
	}

/* Content Wrapper */
.Recipes_ContentArea__1hJ7P{
	width: 100%;
	padding: 0;
	max-width: 100%;
	/*display: flex;*/
}

	/* RESULTS */
	.Recipes_ContentArea__1hJ7P > article{
		width:100%;			/* collapse/expand when filter visible/hidden */
	}

	/* FILTER WRAPPER */
	.Recipes_ContentArea__1hJ7P > aside{
		/*width:0;*/ 		/* width is controlled by > filter child */
		/*position: absolute;*/
	}


@media screen and (min-width: 768px){
	.Recipes_Recipes__10uqW{
		display: flex;
		align-items: center;
	    flex-direction: column;
	}

	.Recipes_ContentArea__1hJ7P{
		display: flex;
	}
}

@media screen and (min-width: 992px){
	.Recipes_ContentArea__1hJ7P{
		padding: 2em;
	}	
}
.Filters_Aside__3HR3h{
	position: absolute; /* float over results */
	z-index:994;
	margin-top: 1em;
	
	position: -webkit-sticky;
	
	position: sticky; /* make sticky*/
	top: 70px;
	display: inline-block;
	height: 0; /* keep content float up*/
}
	.Filters_FilterHide__34ly3{
		width: 1px; 
		transition: width .2s ease-out .1s;
	}
	.Filters_FilterShow__1m1vr{
		width: auto;
	}

/* TOGGLE BTN */
.Filters_ToggleBtn__m8JBO{
	z-index: 995;
    right: -1em; /* position @ filter right*/
    top: 1.5em;
    position: absolute;
    font-size: 1em;
    background-color: var(--color__green-light3);
    padding: 1em;
    border-radius: 1em;
    box-shadow: 7px -1px 7px #5050571f;
}
	.Filters_ToggleBtn__m8JBO:hover{
		cursor: pointer;
		color: var(--color__green);
	}

/* FILTER WRAPPER */
.Filters_Wrapper__ZbtFh{
	position: relative;	/* for icon */
	background-color: var(--color__green-light3); 
	padding: 2em 1em;
	border-radius: .75em;
    box-shadow: 10px 0 10px #5050571f;
	float: left;
	transition: all .3s ease-out .1s;
}
	/* SHOW */
	.Filters_FilterShow__1m1vr .Filters_Wrapper__ZbtFh{
		opacity: 1;
		visibility: visible;
		width: 200px;	/* controls parnet width */
		left: 0; 
	}


	/* HIDE */
	.Filters_FilterHide__34ly3 .Filters_Wrapper__ZbtFh{
		opacity: 0;
		visibility: hidden; 
		width: 0;
		left: -200px; 
	}
		.Filters_FilterShow__1m1vr .Filters_ToggleBtn__m8JBO{
		    right: -1em; 		/* toggle button placment*/
		}


/* FILTER GROUP */
.Filters_GroupWrapper__2S95v{
	height: 70vh;
    overflow: auto;
    padding-right: 1em; /* space fore scrollbar */
}

.Filters_Group__43tlG{
	position: relative;
	border: 0;
	margin: 0;
	padding: 0;
	border-bottom: 1px solid var(--color__white-light);
    padding-bottom: 1em;
	transition: all .2s ease-in .1s;
}
	/*disabled*/
	.Filters_Group__43tlG:disabled label{
	}
	
	.Filters_Group__43tlG:disabled label{
		color: var(--color__gray-light);
	}
	
	/* group title */
	.Filters_GroupTitle__3OEAz{
		font-family: var(--fontfamily__normal);
		color: var(--color__green-dark);
		font-size: .85em;
	}
	/* group label */
	.Filters_Label__2kZuc{
		font-size: .85em;
	}
	/* group checkbox */
	.Filters_Input__3nHzX{
		position: absolute;
		right: 0;
	}

	/* filter load indicator */
	.Filters_Group__43tlG .filter-load-indicator{
		display:none;
		position: absolute;
	    right: 1.5em;
	    margin-top: 2.5px;
	    color: var(--color__green);;
	}
		.Filters_Group__43tlG .filter-load-indicator.current-select{
			display:block;
		}
	

/* SINGLE group */
.Filters_SingleFilter__12_VN{
	font-family: var(--fontfamily__normal);
	color: var(--color__green-dark);
	margin-bottom: 1em;
	font-weight: 600;
}


@media screen and (min-width: 576px){
	.Filters_Group__43tlG .filter-load-indicator{
	   right: 1em;
	}
}

@media screen and (min-width: 992px){
	.Filters_Aside__3HR3h{
		position: relative; /* remove float over results */
		height: auto;
	}
	.Filters_GroupWrapper__2S95v{
		height: auto;
	    overflow: inherit;
	}

	.Filters_ToggleBtn__m8JBO{
	    top: 3.5em;
	}

	.Filters_Wrapper__ZbtFh{
		padding: 2em;
	}
}
.Card_Card__3Y4Mv{
	box-sizing: border-box;
    background-color: var(--color__white-light);
    border-radius: .5em;
    padding: .5em;
    margin: 1%;
    width: 100%;
    cursor: pointer;
}


.Card_Card__3Y4Mv > .cardImg {
	box-sizing: border-box;
	border-radius: .5em;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	
	width:100%;
	height: 100px;
}

.Card_Card__3Y4Mv > .cardContent {
	box-sizing: border-box;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	
	width: 100%;
}
	
	.Card_Card__3Y4Mv > .cardContent > h3 {
		box-sizing: border-box;
		margin: .5em 0;
	    letter-spacing: .05em;
	    font-size: 1.5em;
	    font-weight: 300;
	    line-height: 1;
	    text-transform: capitalize;
	}

@media screen and (min-width: 576px ){
	.Card_Card__3Y4Mv{
		display: flex;
		text-decoration: none;
	}

	.Card_Card__3Y4Mv > .cardImg {
		width:30%;
		height: inherit;
	}

	.Card_Card__3Y4Mv > .cardContent {
		width: 70%;
		padding: 0 1em;
	}
}

@media screen and (min-width: 1200px ){
	.Card_Card__3Y4Mv{
		width: 48%;
	}
}
.Favorites_AddToFav__3DfvB{
	font-size: 1.15em;
    color: rgba(255,255,255, 0.5);
    background-color: rgba(0,0,0, 0.25);
    margin: .5em;
    padding: .35em .3em 0;
    display: inline-block;
    border-radius: 2em;
}

	.Favorites_AddToFav__3DfvB:hover,
	.Favorites_AddToFavActive__37NOd:hover{
		cursor: pointer;
		background-color: rgba(0,0,0, 0.5);
	}

	/*active*/
	.Favorites_AddToFavActive__37NOd{
		color: var(--color__red);
	    background-color: rgba(255,255,255, 0.25);
	}
.ListTag_Tag__3JRDe{
	font-size: 0.75em;
	text-transform: capitalize;
	color: var(--color__gray-dark);
	list-style: none;
	display: inline-block;
	background-color: var(--color__gray);
	padding: .15em .5em;
	border-radius: .25em;
	margin: .15em;
}

	.ListTag_TagIncluded__3XD_P{
		background-color: var(--color__green);
	}
	.ListTag_TagNotincluded__4sj4O{
		background-color: var(--color__gray-light);
	}
.ListFacts_FactList__3OuG4{
	margin: 1em 0;
}

/* data */
.ListFacts_FactList__3OuG4 > span{
	margin-right: 1em;
}

/* icon */
.ListFacts_FactList__3OuG4 > span > svg{
	vertical-align: middle;
	color: var(--color__green);
}
.ListRecipes_QuickSearch__KL7EJ{
	width: 100%;
	margin-top: 1em;
}


.ListRecipes_Pagi__2tJz0{
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	width: 100%;
	position: relative;
    border-radius: .25em;
	padding: 0 2em 0 0;
}

	.ListRecipes_Pagi__2tJz0 > li{
		cursor: pointer;
		margin: .15em 0;
		line-height: 1;
		
		background-color: rgba(245, 252, 249, .5);
    	padding: .25em .4em;
	}
	.ListRecipes_Pagi__2tJz0 > li.displayTotal{
		cursor: inherit;
		position: absolute;
		color: var(--color__gray);
		right: 0;
	}
	.ListRecipes_Pagi__2tJz0 > li.active{
		cursor: inherit;
		font-weight: 600;
		color: var(--color__green);
		border-bottom: 1px solid var(--color__green);
		/*padding: .1em .5em;*/
		/*border-radius: 1em;*/
	}
.RecipeSingle_Title__3Y5qW{
	display: block;
	font-size: .9em;
	color: var(--color__green-dark3) !important;
}
	.RecipeSingle_Credit__2WJjA{
		margin-top: 1.15em;
		display: block;
		font-size: .35em;
		font-family: var(--fontfamily__normal) !important;
		text-decoration: none !important;
	}
		.RecipeSingle_Credit__2WJjA > a{
			text-decoration: none !important;
		}

.RecipeSingle_Wrapper__1wlqD{
	font-size: .8em;
}

.RecipeSingle_ContentWrapper__2oAFI{
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.RecipeSingle_ImgWrapper__3C8sO{
	width: 100%;
	background-size: cover;
	background-position: center;
	border-radius: 1em;
	min-height: 150px;
}

.RecipeSingle_InfoWrapper__1nPDM{
	width: 100%;
}

.RecipeSingle_InfoWrapper__1nPDM > .tagList > span{
	font-size: .85em;
	padding: .3em 1em;
}	

.RecipeSingle_H2__1ZiKQ{
	color: var(--color__green-dark);
	letter-spacing: .05em;
	font-weight: normal;
}

.RecipeSingle_InstructionWrapper__3T5Ni{
	width: 100%;
	margin-top: 2em;
}
	.RecipeSingle_InstructionList__3Gp4d{
		list-style: none;
		padding: 0;
		margin: 0;
	}

	.RecipeSingle_InstructionList__3Gp4d > li{
		margin-bottom: 1em;
	}

	.RecipeSingle_InstructionList__3Gp4d > li > span{
		font-weight: normal;
	    font-family: var(--fontfamily__header);
	    color: var(--color__green-dark3);
	    font-size: 1.5em;
	    letter-spacing: .05em;
	}


@media screen and (min-width: 576px){
	.RecipeSingle_ContentWrapper__2oAFI{
		flex-direction: column;
	}
	.RecipeSingle_ImgWrapper__3C8sO{
		min-height: 250px;
	}
}

@media screen and (min-width: 768px){
	.RecipeSingle_ImgWrapper__3C8sO{
		min-height: 300px;
	}
}
.NotFound_NotFoundWrapper__1jWvq{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}
	
	.NotFound_NotFoundWrapper__1jWvq img{
		margin-top: 5%;
		max-width: 250px;
	}
	.NotFound_Title___7ZYf{
		font-size: 1.75em;
	}
		.NotFound_Title___7ZYf.error{
			color: var(--color__red-light);
		}
	.NotFound_SubTitle__2Z8eK{
		text-align: center;
		font-size: 1em;
		margin-bottom: 1em;
		color: var(--color__gray);
	}
	.NotFound_Suggestion__iNy3h{
		text-align: center;
		color: var(--color__gray);
	}
		.NotFound_Suggestion__iNy3h svg{
			font-size: .5em;
			margin: 0 1em;
		}

@media screen and (min-width: 576px){
	.NotFound_NotFoundWrapper__1jWvq{
		padding: 0 10%; 
	}

}
 .Pantry_Pantry__3W2uy{
	box-sizing: border-box;
	font-size: .875rem; 		/* controls all included comps measurements */
	display: flex;
	align-items: center;
    flex-direction: column;
}

.Pantry_AddIngWrapper__mt80O,
.Pantry_ContentArea__1PtWT{
	position: relative;
	min-width: 96%;
	max-width: 96%;
}

	.Pantry_AddIngWrapper__mt80O{
		display: flex;
		align-items: center;
		justify-content: center;
	}
		.Pantry_AddIngWrapper__mt80O form{
			flex:0 0 80%;
			max-width: none;
			min-width: none;
		}
		.Pantry_Or__1P9hl{
			display: none;
			font-size: 1.25em;
			padding-left:.5em;
		}
		.Pantry_List__1jARK{
			padding-left: 10px;
			font-size: 3.25em;
			color: var(--color__green-dark);
		}
			.Pantry_List__1jARK:hover{
				cursor: pointer;
				color: var(--color__green-dark3);
			}

	.Pantry_PreDefinedListTrigger__1QWGY{
		margin-top: 1em;
		/*font-size: .9em;*/

	}




@media screen and (min-width: 576px){
	.Pantry_Or__1P9hl{
		display: inherit;
	}
}

@media screen and (min-width: 768px){
	.Pantry_AddIngWrapper__mt80O,
	.Pantry_ContentArea__1PtWT{
		min-width: 80%;
		max-width: 80%;
	}
}

@media screen and (min-width: 992px){
	.Pantry_AddIngWrapper__mt80O,
	.Pantry_ContentArea__1PtWT{
		min-width: 60%;
		max-width: 60%;
	}
}

@media screen and (min-width: 1200px){
	.Pantry_AddIngWrapper__mt80O,
	.Pantry_ContentArea__1PtWT{
		min-width: 80%;
		max-width: 80%;
	}
}
/* size is controlled by parent font-size */
.PantryIng_Form__dBvEv,
.PantryIng_ContentArea__LdIQE{
	position: relative;
	min-width: 96%;
	max-width: 96%;
}

/* SEARCH/ADD */
.PantryIng_Input__ToUdN{
    box-sizing: border-box;
    height: 3.5em;
    width: 100%;
    font-size: 1.15em;
	padding: 0 2em;
    border: 1px solid var(--color__green);
    border-radius: .25em;
}


.PantryIng_ListIcon__2qztB{
	position: absolute;
    top: 0;
    line-height: 5.25em;
    right: 1.75em;
	color: var(--color__gray-dark);
}
	.PantryIng_ListIcon__2qztB > svg{
		font-size: 1.5em;
	}

	/* FILTER */
	.PantryIng_Filter__EFnCM{
		box-sizing: border-box;
		border-radius: .25em;
		margin: 1em 0 0;
		padding: 0 1em;
		background-color: rgba(255, 255, 255, 0.75);

		display: flex;
    	justify-content: space-between;
    	align-items: center;
		margin: 2em 0 0;
		padding: 0 1em;
	}
		/* ing search input */
		.PantryIng_IngSearchInputWrap__1HJQS{
			flex: 0 1 50%;
    		font-size: .85em;
		}
			.PantryIng_IngSearchInputWrap__1HJQS input{
				background-color: var(--color__white-light);
			}

	/* TABLE */
	.PantryIng_Table__3oGfb{
		box-sizing: border-box;
		padding: 0;
		margin: 1em 0;
		border-spacing: 0;
		width: 100%;
	}

	.PantryIng_Table__3oGfb > thead > tr > th,
	.PantryIng_Table__3oGfb > tbody > tr > td{
		font-weight: normal;
		padding: .5em .75em;
		text-align: left;
		vertical-align: top;
		border-bottom: 1px solid var(--color__gray-light);
	}

	.PantryIng_Table__3oGfb > thead > tr > th{
		color: var(--color__white);
		background-color: var(--color__gray-dark);
	}

		.PantryIng_Table__3oGfb > tbody > tr > td{
			transition: all 1s ease-out .1s;
		}
			/* highlight */
			.PantryIng_Table__3oGfb > tbody > tr.short-highlight > td{
				background-color: var(--color__green-light2);
			}
			/* cart rows */
			.PantryIng_Table__3oGfb > tbody > tr.shop > td{
				background-color: var(--color__yellow);
			}
			.PantryIng_Table__3oGfb > tbody > tr.short-highlight-delete > td{
				background-color: var(--color__red);
			}

		/* title case for ing name */
		.PantryIng_Table__3oGfb > tbody > tr > td:first-child{
			text-transform: capitalize;
		}
		/* first 2 cols ing, asile */
		.PantryIng_Table__3oGfb > tbody > tr > td:nth-child(1){
			width: 35%;
		}
		.PantryIng_Table__3oGfb > tbody > tr > td:nth-child(2){
			width: 65%;
		}
		/* last 2 cols delete, cart */
		.PantryIng_Table__3oGfb > tbody > tr > td:nth-child(3),
		.PantryIng_Table__3oGfb > tbody > tr > td:nth-child(4){
			width: auto;
			text-align: center;
		}

		/* icon delete */
		.PantryIng_IconDelete__3J7_4{
			color: var(--color__red-light);
			margin: auto; /* center to cel */
		}
			.PantryIng_IconDelete__3J7_4.disabled{
				color: var(--color__gray-light);
			}

			.PantryIng_IconDelete__3J7_4:hover{
				cursor: pointer;
				color: var(--color__red-dark);
			}
		
		/* cart checkbox */
		.PantryIng_CartCheck__322IG{
			border-color: var(--color__red-light);
			margin: auto; /* center to cel */
		}
			.PantryIng_CartCheck__322IG:hover{
				cursor: pointer;
				color: var(--color__red-dark);
			}


/* PREDIFINED INGS */
	/* wrapper */
	.PantryIng_PredefinedList__16l1f{
		display: flex;
		justify-content: space-aorund;
		flex-wrap: wrap;
		margin: 1em 0;
	}

	/* items */
	.PantryIng_PredefinedItem__16BHy{
		flex: 0 0 45%;
		justify-content: space-between;
		padding: .75em 1em;
		margin: .5em;
		cursor: pointer;
		text-align: center;

		background-color: var(--color__white);
		border-radius: .5em;
	}
		.PantryIng_PredefinedItem__16BHy:hover{
			background-color: var(--color__blue-dark);
		}

		/* icon */
		.PantryIng_PredefinedItem__16BHy svg{
			font-size: 2.5em;
			color: var(--color__green-dark);
		}
		/* title */
		.PantryIng_PredefinedItem__16BHy h3{
			color: var(--color__green-dark3);
			font-size: 1em;
			letter-spacing: 0.1em;
			line-height: 1;
			margin: .25em;
			font-weight: normal;
			text-align: center;
		}
		
			.PantryIng_PredefinedItem__16BHy:hover h3,
			.PantryIng_PredefinedItem__16BHy:hover svg{
				color: var(--color__white-light);
			}
		
		.PantryIng_PredefinedItem__16BHy.PantryIng_CurrentSelected__3Ti1C{
			background-color: var(--color__blue-dark);
		}
			.PantryIng_PredefinedItem__16BHy.PantryIng_CurrentSelected__3Ti1C h3,
			.PantryIng_PredefinedItem__16BHy.PantryIng_CurrentSelected__3Ti1C svg{
				color: var(--color__white-light);
			}



@media screen and (min-width: 576px){
	.PantryIng_IngSearchInputWrap__1HJQS{
		font-size: inherit;
	}
	.PantryIng_PredefinedItem__16BHy{
		flex: 0 0 22%;
	}
}

@media screen and (min-width: 768px){
	.PantryIng_Form__dBvEv,
	.PantryIng_ContentArea__LdIQE{
		min-width: 80%;
		max-width: 80%;
	}
	.PantryIng_Input__ToUdN{
	    font-size: 1.25em;
	}

	.PantryIng_Table__3oGfb > thead > tr > th{
		padding: 1em;
	}
}

@media screen and (min-width: 992px){
	.PantryIng_Form__dBvEv,
	.PantryIng_ContentArea__LdIQE{
		min-width: 60%;
		max-width: 60%;
	}
}

@media screen and (min-width: 1200px){
	.PantryIng_Form__dBvEv,
	.PantryIng_ContentArea__LdIQE{
		min-width: 80%;
		max-width: 80%;
	}
}
 .Favorites_Favorites__1_7nC{
	/*box-sizing: border-box;*/
	font-size: .875rem; 		/* controls all included comps mesurments */
	display: flex;
	align-items: center;
    flex-direction: column;
}

	/* Search */
	.Favorites_Search__h1-dR{
		margin: 1em 0 3em;
	}

/* Content Wrapper */
.Favorites_ContentArea___WgAv{
	width: 100%;
	display: flex;
	padding: 0;
	max-width: 100%;
}

	/* RESULTS */
	.Favorites_ContentArea___WgAv > article{
		width:100%;			/* collapse/expand when filter visible/hidden */
	}

	/* FILTER WRAPPER */
	.Favorites_ContentArea___WgAv > aside{
		width:auto; 		/* width is controlled by > filter child */
	}

@media screen and (min-width: 992px){
	.Favorites_ContentArea___WgAv{
		padding: 2em;
	}	
}
.FooterMenu_Nav__3vlII {
	display: none;
	align-items: center;
	justify-content: center;
}

.FooterMenu_Nav__3vlII > .menu{
	/*flex: 0 0 94%;*/
	/*display: none;*/
}

	@media screen and (min-width: 576px ) {
		
		.FooterMenu_Nav__3vlII {
			display: flex;
			margin-right: .5rem;	/* add gap with copyright text*/
		}

		/*.Nav:global > .menu{
			flex: 0 0 94%;
			display: flex;
		}*/		
	}

.FooterMenu_Nav__3vlII > .menu > a{
	padding: 0 1rem;
	border-right: 1px solid var(--color__gray);
	text-decoration: none;

	font-size: var(--font__small);
	color: var(--color__gray);
}

	.FooterMenu_Nav__3vlII > .menu > a:last-child{
		border-right: 0;
	}

	.FooterMenu_Nav__3vlII > .menu > a:hover{
		color: var(--color__green);
	}
/* Layout */

header, 
main, 
footer{
	max-width: var(--screen__xl);
	padding: 1rem;
	margin: auto;
	box-sizing: border-box;
}

.wrapper{
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center 58px;	/* 58px header clear */
	transition: all .3s ease-out;
}
	/* keep non-home wrappers contain*/
	.wrapper:not(.wrapper-home){
		background-size: contain !important;
	}


header{
	width: 100%;
	min-width: 100%;
	z-index: 996;
	transition: all .2s ease-in;
	background-color: var(--color__white-light);
}
	/* sticky header */
	.isSticky header{
		position: fixed;
		background-color: var(--color__white-light);
		box-shadow: 0 2px 10px -5px var(--color__gray);
	}

	header div{
		display: flex;
		max-width: var(--screen__xl);
		margin: auto;
	}

	header div .logo{
		flex:0 0 30%;
	}

		header div .logo img{
			max-height: 32px;
			width: auto;
			max-width: 100%;
		}

	header div nav{
		flex:0 1 70%;
	}


footer{
	min-height: 74px; /* to maintain full-VH in <main>*/
	max-height: 74px;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

	.copyright-text{
		margin-top: .75em;
		font-size: var(--font__small);
		color: var(--color__gray-light);
	}


@media screen and (orientation: portrait){
	.wrapper{
		background-size: cover;
		background-position: center 58px;
	}
}

	@media screen and (orientation: portrait) and (min-width: 768px){
		.wrapper{
			background-size: cover;
			background-position: 80% 58px;
		}
	}

@media screen and (min-width: 1900px){
	.wrapper{
		background-size: cover;		/* stretch for higher screens */
	}
}
