
/* wrapper */
.Wrapper{
	box-sizing: border-box;
	width: 90%;
	margin: 1em 0 0;
	display: flex;
	flex-wrap: wrap;
}

/* Quick Search Item */
/* featured */
.Featured{
    flex: 1 0 40%;
	display: none
}
.SearchList{
    display: flex;
    flex: 1 0 60%;
    flex-wrap: wrap; 
}
	.Item {
		box-sizing: border-box;
	    flex: 1 0 44%;
	    margin: 1%;
	    min-height: 6.5em;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    flex-direction: column;
	    background-color: var(--color__green);
	    color: var(--color__white);
		text-decoration: none;
	}

		.Item :global svg {
			font-size: 2em;
		}

		.Item :global p {
			font-size: .75em;
			letter-spacing: .05em;
			color: var(--color__white);
			margin: .5em 0 0;
		}
		
		.Item:hover {
			cursor: pointer;
			background-color: var(--color__green-dark);
		}



	@media screen and (orientation: portrait){
		.Wrapper{
			font-size: 1.25em; /* increase icon size in potrait view */
		}

	}

	@media screen and (min-width: 576px ){
		.Wrapper{
			flex-wrap: nowrap;
			width: 75%;
		}
		.Item {
			flex: 1 0 15%;
			margin: 0;
		}
	}

	@media screen and (min-width: 768px ){
		.Wrapper{
			margin: 2em 0 0;
			/* featured collapse below*/
			width: 60%;
			flex-direction: column;
		}
		.Featured{
			box-sizing: border-box;
			font-size: .5em;
			flex: 1 0 40%;
			background-color: var(--color__white-light);
			padding: 0 3em;
			display: flex;
			align-items: center;
		}
	}

	@media screen and (min-width: 992px ){
		.Wrapper{
			width: 80%;
			flex-direction: row;
		}
	}

	@media screen and (min-width: 1600px ){
		.Wrapper{
			width: 100%;
			max-width: 100%;
		}
	}