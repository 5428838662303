.Card{
	box-sizing: border-box;
    background-color: var(--color__white-light);
    border-radius: .5em;
    padding: .5em;
    margin: 1%;
    width: 100%;
    cursor: pointer;
}


.Card:global > .cardImg {
	box-sizing: border-box;
	border-radius: .5em;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	
	width:100%;
	height: 100px;
}

.Card:global > .cardContent {
	box-sizing: border-box;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	
	width: 100%;
}
	
	.Card:global > .cardContent > h3 {
		box-sizing: border-box;
		margin: .5em 0;
	    letter-spacing: .05em;
	    font-size: 1.5em;
	    font-weight: 300;
	    line-height: 1;
	    text-transform: capitalize;
	}

@media screen and (min-width: 576px ){
	.Card{
		display: flex;
		text-decoration: none;
	}

	.Card:global > .cardImg {
		width:30%;
		height: inherit;
	}

	.Card:global > .cardContent {
		width: 70%;
		padding: 0 1em;
	}
}

@media screen and (min-width: 1200px ){
	.Card{
		width: 48%;
	}
}