 .Favorites{
	/*box-sizing: border-box;*/
	font-size: .875rem; 		/* controls all included comps mesurments */
	display: flex;
	align-items: center;
    flex-direction: column;
}

	/* Search */
	.Search{
		margin: 1em 0 3em;
	}

/* Content Wrapper */
.ContentArea{
	width: 100%;
	display: flex;
	padding: 0;
	max-width: 100%;
}

	/* RESULTS */
	.ContentArea:global > article{
		width:100%;			/* collapse/expand when filter visible/hidden */
	}

	/* FILTER WRAPPER */
	.ContentArea > aside{
		width:auto; 		/* width is controlled by > filter child */
	}

@media screen and (min-width: 992px){
	.ContentArea{
		padding: 2em;
	}	
}