/* size is controlled by parent font-size */

.SearchForm{
	position: relative;
	min-width: 90%;
}

.SearchForm :global .MuiAutocomplete-inputRoot{
	padding-right: 1em !important;
	background-color: var(--color__white-light);
}

.SearchForm :global .MuiAutocomplete-input{
}

.SearchForm :global .MuiInputLabel-root{
	color: var(--color__gray-light);
}
	.SearchForm :global .MuiInputLabel-root.MuiInputLabel-shrink{
		color: var(--color__green);
	}

.SearchForm :global .MuiAutocomplete-inputRoot .MuiOutlinedInput-notchedOutline{
	border: 1px solid var(--color__green);
}
	.SearchForm :global .MuiAutocomplete-inputRoot:hover .MuiOutlinedInput-notchedOutline{
		border: 1px solid var(--color__green-dark);
	}
	.SearchForm :global .MuiAutocomplete-inputRoot.Mui-focused .MuiOutlinedInput-notchedOutline{
		border: 1px solid var(--color__green-dark);
		/*border-color: var(--color__green-dark);*/
		box-shadow: 0px 0px 4px 0px var(--color__green);
	}

.SearchForm :global .MuiAutocomplete-inputFocused{

}

.SearchForm :global .MuiAutocomplete-tagSizeSmall{

}

.SearchForm :global .MuiAutocomplete-endAdornment{

}

.SearchIcon{
	padding-left: 1em;
}
	.SearchIcon > button{
		background: none;
		padding: 0;
		margin:0;
    	color: var(--color__gray-light);
    	line-height: 0;
	}
	
	.SearchIcon > button > svg{
		font-size: 1.5em;
	}
	/* when search has values */
	.SearchForm :global .MuiOutlinedInput-adornedStart .iconSearch svg{
    	color: var(--color__green);
	}


/* search by section */
/* .SearchTypeWrapper{
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--color__green);
	margin-top: .25em;
} */

.SearchTypeWrapper{
    position: relative;
	display: flex;
    justify-content: center;
    align-items: center;
    font-size: .9em;
    color: var(--color__green);
    background-color: var(--color__white-light);
    margin: auto;
    padding-top: .5em;
    padding-bottom: .5em;
    width: 100%;
    margin-top: -1px;
    border-radius: 0 0 .5em .5em;
    border: 1px solid;
    border-top: 0;
    border-color: var(--color__green);
    box-shadow: 0px 4px 3px 1px #0000002e
}
	.SearchTypeWrapper:before {
	    content: '';
	    width: 100%;
	    position: absolute;
	    background: transparent;
	    height: 1px;
	    top: -1px;
	    box-shadow: inset 0px 0px 1px 0px var(--color__green-light2);
	}

	.SearchTypeWrapper.NonPantryMatch :global input[type=radio]{
		display: none;
	}
		.SearchTypeWrapper.NonPantryMatch :global .input-wrapper:last-child{
			margin-left: 1.25em; 
		}

	.SearchTypeWrapper.NonPantryMatch :global label{
		cursor: pointer;
		font-size: .95em;
		display: flex;
		align-items: center;
	}

	.SearchTypeWrapper.NonPantryMatch :global label:before {
		  content: "";
		  width: 16px;
		  height: 16px;
		  border-radius: 8px;
		  margin-right: 8px; 
		  background-color: rgba(202, 215, 146, .68);
		  box-shadow: inset 0px 2px 3px 0px rgba(0, 0, 0, .175), 0px 1px 0px 0px rgba(255, 255, 255, .8);
	}
		.SearchTypeWrapper.NonPantryMatch :global label.isChecked:before {
		    content: "\2022";
		    color: #fff;
		    font-size: 30px;
		    text-align: center;
		    line-height: .5;
		}

/* ing filter */
/* .IngFilterWrapper{
	display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color__green);
    margin-top: .25em;
    background-color: rgba(245, 252, 249, .65);
    padding: .5em 1.25em;
    border-radius: .5em;
    max-width: 70%;
    margin: .5em auto 0;
} */
	.SearchTypeWrapper.PantryMatch{
		padding-left: 1.25em;
		padding-right: 1.25em;
		/*width: 60%;*/
	}
		.SearchTypeWrapper.PantryMatch:global > label{
			white-space: nowrap;
    		font-size: .95em;
		}
		.SearchTypeWrapper.PantryMatch:global > .MuiSlider-root{
    		margin-right: .5em;
    		margin-left: 1em;
		}
			.SearchTypeWrapper.PantryMatch :global .MuiSlider-rail {
				padding: .15em;
				border-radius: .5em;
				background-color: var(--color__green);
			}
			.SearchTypeWrapper.PantryMatch :global .MuiSlider-track {
				padding: .15em;
				border-radius: .5em;
				background-color: var(--color__green-dark);
				outline: 1px solid var(--color__green-light3);
			}
			.SearchTypeWrapper.PantryMatch :global .MuiSlider-thumb {
				height: 16px;
				width: 16px;
				background-color: var(--color__green-dark);
				border: 2px solid var(--color__green-light3);
			}
			.SearchTypeWrapper.PantryMatch :global .MuiSlider-valueLabel > span {
				background-color: var(--color__green-dark);

			}



@media screen and (min-width: 576px){
	.SearchForm{
		min-width: 70%;
	}

	.SearchTypeWrapper{
	    width: 75%;
	}
}

@media screen and (min-width: 768px){
	.SearchForm{
		min-width: 60%;
		max-width: 60%;
	}
	.Input{
	    font-size: 1.25em;
	}
}

@media screen and (min-width: 992px){
	.SearchTypeWrapper{
	    width: 55%;
	}
}

@media screen and (min-width: 1600px){
	.SearchForm{
		min-width: 80%;
		max-width: 80%;
	}
	.SearchTypeWrapper :global label{
		font-size: .75em;
	}
}

