/* notice */
.Alert {
    position: relative;
    padding: .75em 2em .75em 1em;
    margin: 1rem auto;
    border: 1px solid transparent;
    border-radius: .25rem;
    font-size: .85em;
	background-color: var(--color__gray-light);
	border-color: var(--color__gray);
}
	.Alert:global.vcenter {
		/*margin: 2%;*/
	}

	.Alert:global.error {
		background-color: var(--color__red-light);
		border-color: var(--color__red);
	}
	.Alert:global.success {
		background-color: var(--color__green);
		border-color: var(--color__green-dark);
	}
	.Alert:global.warning {
		background-color: var(--color__yellow);
		border-color: var(--color__yellow-dark);
	}
	.Alert:global.notice {
		background-color: var(--color__blue-light);
		border-color: var(--color__blue);
	}

	.Alert strong {
		text-transform: capitalize;	/* error type prop is lower case*/
	}

	.Alert svg {
		opacity: .25;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: .5em;
	}

	.Alert svg:hover {
		opacity: 1;
		cursor: pointer;
	}

	.Alert:global.regular svg{
		display: none;
	}

@media screen and (min-width: 768px){
	.Alert:global.vcenter {
		margin: 10%;
	}
}