/* size is controlled by parent font-size */
.Form,
.ContentArea{
	position: relative;
	min-width: 96%;
	max-width: 96%;
}

/* SEARCH/ADD */
.Input{
    box-sizing: border-box;
    height: 3.5em;
    width: 100%;
    font-size: 1.15em;
	padding: 0 2em;
    border: 1px solid var(--color__green);
    border-radius: .25em;
}


.ListIcon{
	position: absolute;
    top: 0;
    line-height: 5.25em;
    right: 1.75em;
	color: var(--color__gray-dark);
}
	.ListIcon > svg{
		font-size: 1.5em;
	}

	/* FILTER */
	.Filter{
		box-sizing: border-box;
		border-radius: .25em;
		margin: 1em 0 0;
		padding: 0 1em;
		background-color: rgba(255, 255, 255, 0.75);

		display: flex;
    	justify-content: space-between;
    	align-items: center;
		margin: 2em 0 0;
		padding: 0 1em;
	}
		/* ing search input */
		.IngSearchInputWrap{
			flex: 0 1 50%;
    		font-size: .85em;
		}
			.IngSearchInputWrap :global input{
				background-color: var(--color__white-light);
			}

	/* TABLE */
	.Table{
		box-sizing: border-box;
		padding: 0;
		margin: 1em 0;
		border-spacing: 0;
		width: 100%;
	}

	.Table:global > thead > tr > th,
	.Table:global > tbody > tr > td{
		font-weight: normal;
		padding: .5em .75em;
		text-align: left;
		vertical-align: top;
		border-bottom: 1px solid var(--color__gray-light);
	}

	.Table:global > thead > tr > th{
		color: var(--color__white);
		background-color: var(--color__gray-dark);
	}

		.Table:global > tbody > tr > td{
			transition: all 1s ease-out .1s;
		}
			/* highlight */
			.Table:global > tbody > tr.short-highlight > td{
				background-color: var(--color__green-light2);
			}
			/* cart rows */
			.Table:global > tbody > tr.shop > td{
				background-color: var(--color__yellow);
			}
			.Table:global > tbody > tr.short-highlight-delete > td{
				background-color: var(--color__red);
			}

		/* title case for ing name */
		.Table:global > tbody > tr > td:first-child{
			text-transform: capitalize;
		}
		/* first 2 cols ing, asile */
		.Table:global > tbody > tr > td:nth-child(1){
			width: 35%;
		}
		.Table:global > tbody > tr > td:nth-child(2){
			width: 65%;
		}
		/* last 2 cols delete, cart */
		.Table:global > tbody > tr > td:nth-child(3),
		.Table:global > tbody > tr > td:nth-child(4){
			width: auto;
			text-align: center;
		}

		/* icon delete */
		.IconDelete{
			color: var(--color__red-light);
			margin: auto; /* center to cel */
		}
			.IconDelete:global.disabled{
				color: var(--color__gray-light);
			}

			.IconDelete:hover{
				cursor: pointer;
				color: var(--color__red-dark);
			}
		
		/* cart checkbox */
		.CartCheck{
			border-color: var(--color__red-light);
			margin: auto; /* center to cel */
		}
			.CartCheck:hover{
				cursor: pointer;
				color: var(--color__red-dark);
			}


/* PREDIFINED INGS */
	/* wrapper */
	.PredefinedList{
		display: flex;
		justify-content: space-aorund;
		flex-wrap: wrap;
		margin: 1em 0;
	}

	/* items */
	.PredefinedItem{
		flex: 0 0 45%;
		justify-content: space-between;
		padding: .75em 1em;
		margin: .5em;
		cursor: pointer;
		text-align: center;

		background-color: var(--color__white);
		border-radius: .5em;
	}
		.PredefinedItem:hover{
			background-color: var(--color__blue-dark);
		}

		/* icon */
		.PredefinedItem :global svg{
			font-size: 2.5em;
			color: var(--color__green-dark);
		}
		/* title */
		.PredefinedItem :global h3{
			color: var(--color__green-dark3);
			font-size: 1em;
			letter-spacing: 0.1em;
			line-height: 1;
			margin: .25em;
			font-weight: normal;
			text-align: center;
		}
		
			.PredefinedItem:hover :global h3,
			.PredefinedItem:hover :global svg{
				color: var(--color__white-light);
			}
		
		.PredefinedItem.CurrentSelected{
			background-color: var(--color__blue-dark);
		}
			.PredefinedItem.CurrentSelected :global h3,
			.PredefinedItem.CurrentSelected :global svg{
				color: var(--color__white-light);
			}



@media screen and (min-width: 576px){
	.IngSearchInputWrap{
		font-size: inherit;
	}
	.PredefinedItem{
		flex: 0 0 22%;
	}
}

@media screen and (min-width: 768px){
	.Form,
	.ContentArea{
		min-width: 80%;
		max-width: 80%;
	}
	.Input{
	    font-size: 1.25em;
	}

	.Table:global > thead > tr > th{
		padding: 1em;
	}
}

@media screen and (min-width: 992px){
	.Form,
	.ContentArea{
		min-width: 60%;
		max-width: 60%;
	}
}

@media screen and (min-width: 1200px){
	.Form,
	.ContentArea{
		min-width: 80%;
		max-width: 80%;
	}
}