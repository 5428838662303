 .Recipes{
	/*box-sizing: border-box;*/
	font-size: .875rem; 		/* controls all included comps mesurments */
	/*display: flex;
	align-items: center;
    flex-direction: column;*/
}

	/* Search */
	.Search{
		margin: 1em 0 3em;
	}

/* Content Wrapper */
.ContentArea{
	width: 100%;
	padding: 0;
	max-width: 100%;
	/*display: flex;*/
}

	/* RESULTS */
	.ContentArea:global > article{
		width:100%;			/* collapse/expand when filter visible/hidden */
	}

	/* FILTER WRAPPER */
	.ContentArea > aside{
		/*width:0;*/ 		/* width is controlled by > filter child */
		/*position: absolute;*/
	}


@media screen and (min-width: 768px){
	.Recipes{
		display: flex;
		align-items: center;
	    flex-direction: column;
	}

	.ContentArea{
		display: flex;
	}
}

@media screen and (min-width: 992px){
	.ContentArea{
		padding: 2em;
	}	
}