.IntroWrapper{
	padding-top: .25em;
}

.List{
	list-style: none;
	margin: 1em 0 1.25em;
	padding: 0;
	display: flex;
	justify-content: space-between;
}
	.List :global li{
		flex: 0 0 33.333%;
	}
	.List :global li h3{
		font-size: 1.5em;
		letter-spacing: .05em;
		color: var(--color__green);
		text-align: center;
		font-weight: 400;
		margin: 0;
	}
	.List :global li img{
		max-width: 100%;
	}
	.List :global li p{
		font-size: .65em;
		color: var(--color__gray);
		margin: .25em 0;
		text-align: center;
	}

.ShowIntroCheckbox{
	justify-content: center;
	margin-top: 1.25em;
	color: var(--color__gray);
}

.footerWrapper{
	text-align: center;
}