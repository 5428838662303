.Nav {
	display: none;
	align-items: center;
	justify-content: center;
}

.Nav:global > .menu{
	/*flex: 0 0 94%;*/
	/*display: none;*/
}

	@media screen and (min-width: 576px ) {
		
		.Nav {
			display: flex;
			margin-right: .5rem;	/* add gap with copyright text*/
		}

		/*.Nav:global > .menu{
			flex: 0 0 94%;
			display: flex;
		}*/		
	}

.Nav:global > .menu > a{
	padding: 0 1rem;
	border-right: 1px solid var(--color__gray);
	text-decoration: none;

	font-size: var(--font__small);
	color: var(--color__gray);
}

	.Nav:global > .menu > a:last-child{
		border-right: 0;
	}

	.Nav:global > .menu > a:hover{
		color: var(--color__green);
	}