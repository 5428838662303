/* menu items */
	/* main menu icon */
.MenuItemIcon:global.menu-main{
	display: flex;
	font-size: 1.5em;
	vertical-align: middle;	
}
	/* main menu label */
.MenuItemLabel:global.menu-main{
	display: none;	
}
	/* main menu icon */
.MenuItemIcon:global.menu-login{
	margin-right: .5em;
}



@media screen and (min-width: 768px){
	.MenuItemIcon:global.menu-main{
		display: none;	
	}
	.MenuItemLabel:global.menu-main{
		display: inherit;	
	}	
}