@import url("https://fonts.googleapis.com/css2?family=Grand+Hotel&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");


/* variables */
:root {

	/*COLORS*/
	--color__green-dark3:#4C5832;
	--color__green-dark2:#61C790;
	--color__green-dark:#80B636;
	--color__green:#8DC63F;
	--color__green-light:#B1AB52;
	--color__green-light2:#CAD792;
	--color__green-light3:#F5FCF9;

	--color__red-dark: #f44336;
	--color__red: #FE5E5A;
	--color__red-light: #fb7471;
	--color__red-light2: #f7dbd7;
	--color__yellow: #fff3cd;
	--color__yellow-dark: #ffeeba;
	--color__white: #F7F7F5;
	--color__white-light: #FFFFFF;
	--color__gray-dark: #505057;
	--color__gray: #8D8D99;
	--color__gray-light: #CCCCCC;
	--color__gray-light2: #F2F2F2;
	--color__blue-dark: #0c5460;
    --color__blue: #bee5eb;;
    --color__blue-light: #d1ecf1;
	


	/*// TYPOGRAPHY*/
	--fontfamily__normal: 'Merriweather', serif;
	--fontfamily__header: 'Grand Hotel', cursive;;

	--font__xs: 8px;
	--font__small: 12px;
	--font__normal: 14px;
	--font__large: 20px;
	--font__xl: 32px;
	--font__xxl: 60px;


	/*// BREAK POINT*/
	--screen__xs: 0;
	--screen__sm: 576px;
	--screen__md: 768px;
	--screen__lg: 992px;
	--screen__xl: 1200px;
	--screen__xxl: 1600px;

}

*{
	box-sizing: border-box;
}
	svg{
		box-sizing: content-box;
	}

/* make scrollbar appear by default */
/* to avoid layout jumps when scroll conent */
html { 
  	scroll-behavior: smooth;
	overflow-y: scroll; 
} 

body {
	margin: 0;
	font-family: var(--fontfamily__normal);
	font-size: var(--font__normal);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/*background: linear-gradient(to bottom,  #ffffff 58px, #f5fcf9 58px);	*/
	background-color: var(--color__green-light3);
}

body, a, p{
	color: var(--color__gray-dark);
}

h1, h2, h3{
	font-family: var(--fontfamily__header);
	color: var(--color__gray-dark);
}

strong{
	font-weight: 700;
}
.text-primary{
	color: var(--color__green-dark);
}
.text-secondary{
	color: var(--color__gray-dark);
}

#root{
	width: 100%;
}


.input-wrapper{
	position: relative;
	display: flex; 		/* to swap label-left and right */
	flex-wrap: wrap; 	/* to display errortext below */
    margin: .5em 0;
}

/* input error message */
.error-text{
	display: none;
	padding: .5em 0 0;
    color: var(--color__red);
    font-size: .75em;
}
	.error-text.active{
		display: block;
	}
	
/* text variations */
.load-error-small {
    font-size: .75em;
    text-transform: uppercase;
    margin: .25em;
    color: var(--color__red);
    vertical-align: text-top;
}


label {
    display: inline-block;
    /*margin-bottom: .5rem;*/
    /*margin-bottom: 1em;*/
}
	label.label-left {
	    display: inline-block;
	    vertical-align: middle;
	    margin-right: .25em;
	}
		label.label-left + input {
		    display: inline-block;
		}

	label.label-right {
	    display: inline;
	    vertical-align: middle;
	    order: 2; /* swap label with input */
	    margin-left: .25em;
	}
		label.label-right + input {
		    display: inline-block;
		}


input {
	font-family: var(--fontfamily__normal);
    color: var(--color__gray);
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5;
    background-color: var(--color__white);
	
	display: block;
    width: 100%;
    max-width: 100%;
    padding: .5em .75em;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25em;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
} 

	input::placeholder { 
	  color: var(--color__gray-light);
	  opacity: 1;
	}

	input[type=checkbox],
	input[type=radio]{
    	width: inherit;
		height: inherit;
	}
	
	button,
	.btn{
	    font-size: 1em;
	    font-weight: 500;
	    line-height: 1.5;
	    letter-spacing: .05em;
	    text-decoration: none;
	    text-transform: uppercase;
	    text-align: center;
	    color: var(--color__white-light);
	    background-color: var(--color__green-dark);
	    
	    vertical-align: middle;
		display: inline-block;
	    border: 0;
	    user-select: none;
	    padding: .375em 1em;
	    margin-top: .375em;
	    border-radius: .25em;
	    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	}

	button.sm,
	.btn.sm{
	    font-size: .9em;
	    font-weight: 300;
	    line-height: 1;
	    letter-spacing: .05em;
	    padding: .5em 1em;
	    border-radius: .25em;
	}

	button.secondary,
	.btn-secondary{
	    background-color: var(--color__gray);
	}
	
		button:not(:last-child),
		.btn:not(:last-child){
	    	margin-right: .5em;
		}

		button:hover,
		.btn:hover{
			cursor: pointer;
		    background-color: var(--color__green-dark3);
		}



.page-title{
	font-size: 2.15em;
	margin-top: 0;
	line-height: .85;
	text-align: center;
	font-weight: 500;
	letter-spacing: .05em;
	color: var(--color__gray-dark);
}
	.page-title > span{
		color: var(--color__green);
	}


.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}


/* Tooltip container */
.tooltip {
  position: relative;
}

	/* Tooltip text */
	.tooltip .tooltiptext {
	  /*visibility: hidden;*/
	  width: 200px;
	  background-color: var(--color__gray-dark);
	  color: var(--color__white-light);
	  text-align: center;
	  padding: 1em;
	  border-radius: .5em;
	 
	  position: absolute;
	  z-index: 1;
	  box-shadow: 0px 1px 3px 0px var(--color__gray-dark);
	  
	  transition: opacity .4s ease-in;	
	}
		/* arrow */
		.tooltiptext:before{
		    content:'';
		    display:block;
		    width:0;
		    height:0;
		    position:absolute;
		    border-top: 15px solid var(--color__gray-dark);
		    border-right:15px solid transparent;
		    border-left:15px solid transparent;
		    left:45%;
		    bottom:-10px;
		}


/* LOADING ICON */
.loading-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 120px;
	text-align: center;
    animation-iteration-count: 0;
}
	.loading-wrapper img {
		animation: roll 1.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite;	
	}

	.loading-wrapper span {
		margin: 0 0 0 .75em;
		animation: rolltext 1.5s infinite;	
	}

	@keyframes roll {
	  0% { transform: translateY(-10px) rotateX(0); opacity: 1; }
	  40% { transform: translateY(0) rotate(-5deg); opacity: .8; }
	  60% { transform: translateY(0) rotate(5deg); opacity: .8; }
	  100% { transform: translateY(-10px) rotateX(0); opacity: 1; }
	}

	@keyframes rolltext {
	  0% { letter-spacing: inherit; font-weight: normal; }
	  40% { letter-spacing: 2px; font-weight: normal; }
	  60% { letter-spacing: 4px; font-weight: 600; }
	  100% { letter-spacing: inherit; font-weight: normal; }
	}

.fa-spinner{
	animation: spin 1.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite;	
}
	@keyframes spin {
	  0% { transform: rotate(0deg); }
	  100% { transform: rotate(360deg); }
	}

/* global styles for all recipe lists */
/* lists */
.recipeListWrapper{
	box-sizing: border-box;
    display: flex;
    align-items: stretch;
	flex-wrap: wrap;
    padding: 1em;
    align-self: start; /* items < 10 will stretch abnormally */
}
	/* no results */
	.recipeListWrapper.no-results{
		align-items: center;
	    justify-content: start;
	    padding: 2em 4em;
	}

	

@media screen and (min-width: 576px ){
	.page-title{
		font-size: 2.5em;
		margin-top: inherit;
	}
}

@media screen and (min-width: 768px ){
	.page-title{
		margin: .5em;
	}
}