/* home wrapper */
.home{
	font-size: .875rem; /* controls all included comps mesurments */
	box-sizing: border-box;
	display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}


.search,
.searchresults {
	transition: all .3s ease-out;
}

.search-enter{
	/*display: none;*/
	/* position: inherit; */
	opacity: 0;
	transform: translateY(-200%);
}
.search-entering{
	/* position: inherit; */
	opacity: 0.5;
	transform: translateY(-75%);
}
.search-entered{
	/* position: inherit; */
	opacity: 1;
	transform: translateY(0%);
}

.search-exit{
	/* position: inherit; */
	opacity: 1;
	transform: translateY(0%);
}
.search-exiting{
	/* position: inherit; */
	opacity: 0.5;
	transform: translateY(-75%);
}
.search-exited{
	/* position: absolute; */
	opacity: 0;
	transform: translateY(-200%);
	/*display: none;*/
}



.searchresults-enter{
	opacity: 0;
	transform: translateY(100%);
}
.searchresults-entering{
	opacity: 0.5;
	transform: translateY(75%);
}
.searchresults-entered{
	opacity: 1;
	transform: translateY(0%);
}

.searchresults-exit{
	opacity: 1;
	transform: translateY(0%);
}
.searchresults-exiting{
	opacity: 0.5;
	transform: translateY(75%);
}
.searchresults-exited{
	opacity: 0;
	transform: translateY(100%);
}




@media screen and (orientation: portrait){
	.home{ 
	    justify-content: center !important;
		padding-top: 10%;
	}
}

@media screen and (orientation: portrait) and (min-width: 576px){
	.home{ 
		padding-top: 30%;
	}
}

@media screen and (min-width: 992px){
	.home{ 
		font-size: 1rem; 
		padding-top: 8em; 			/* controls search-bar alignment with bg */
		justify-content: start;
	}
}

@media screen and (min-width: 1200px){
	.home{ 
		font-size: 1.15rem; 
		padding-top: 9em;
	}
}

@media screen and (min-width: 1400px){
	.home{ 
		font-size: 1.2rem; 
	}
}

@media screen and (min-width: 1600px){
	.home{ 
		font-size: 1.35rem; 
	}
}

@media screen and (min-width: 1900px){
	.home{ 
		font-size: 1.6rem; 
	}
}