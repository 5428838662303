.NotFoundWrapper{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}
	
	.NotFoundWrapper :global img{
		margin-top: 5%;
		max-width: 250px;
	}
	.Title{
		font-size: 1.75em;
	}
		.Title:global.error{
			color: var(--color__red-light);
		}
	.SubTitle{
		text-align: center;
		font-size: 1em;
		margin-bottom: 1em;
		color: var(--color__gray);
	}
	.Suggestion{
		text-align: center;
		color: var(--color__gray);
	}
		.Suggestion svg{
			font-size: .5em;
			margin: 0 1em;
		}

@media screen and (min-width: 576px){
	.NotFoundWrapper{
		padding: 0 10%; 
	}

}