.Title{
	display: block;
	font-size: .9em;
	color: var(--color__green-dark3) !important;
}
	.Credit{
		margin-top: 1.15em;
		display: block;
		font-size: .35em;
		font-family: var(--fontfamily__normal) !important;
		text-decoration: none !important;
	}
		.Credit:global > a{
			text-decoration: none !important;
		}

.Wrapper{
	font-size: .8em;
}

.ContentWrapper{
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.ImgWrapper{
	width: 100%;
	background-size: cover;
	background-position: center;
	border-radius: 1em;
	min-height: 150px;
}

.InfoWrapper{
	width: 100%;
}

.InfoWrapper:global > .tagList > span{
	font-size: .85em;
	padding: .3em 1em;
}	

.H2{
	color: var(--color__green-dark);
	letter-spacing: .05em;
	font-weight: normal;
}

.InstructionWrapper{
	width: 100%;
	margin-top: 2em;
}
	.InstructionList{
		list-style: none;
		padding: 0;
		margin: 0;
	}

	.InstructionList > li{
		margin-bottom: 1em;
	}

	.InstructionList > li > span{
		font-weight: normal;
	    font-family: var(--fontfamily__header);
	    color: var(--color__green-dark3);
	    font-size: 1.5em;
	    letter-spacing: .05em;
	}


@media screen and (min-width: 576px){
	.ContentWrapper{
		flex-direction: column;
	}
	.ImgWrapper{
		min-height: 250px;
	}
}

@media screen and (min-width: 768px){
	.ImgWrapper{
		min-height: 300px;
	}
}