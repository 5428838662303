.QuickSearch{
	width: 100%;
	margin-top: 1em;
}


.Pagi{
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	width: 100%;
	position: relative;
    border-radius: .25em;
	padding: 0 2em 0 0;
}

	.Pagi:global > li{
		cursor: pointer;
		margin: .15em 0;
		line-height: 1;
		
		background-color: rgba(245, 252, 249, .5);
    	padding: .25em .4em;
	}
	.Pagi:global > li.displayTotal{
		cursor: inherit;
		position: absolute;
		color: var(--color__gray);
		right: 0;
	}
	.Pagi:global > li.active{
		cursor: inherit;
		font-weight: 600;
		color: var(--color__green);
		border-bottom: 1px solid var(--color__green);
		/*padding: .1em .5em;*/
		/*border-radius: 1em;*/
	}