 .Pantry{
	box-sizing: border-box;
	font-size: .875rem; 		/* controls all included comps measurements */
	display: flex;
	align-items: center;
    flex-direction: column;
}

.AddIngWrapper,
.ContentArea{
	position: relative;
	min-width: 96%;
	max-width: 96%;
}

	.AddIngWrapper{
		display: flex;
		align-items: center;
		justify-content: center;
	}
		.AddIngWrapper :global form{
			flex:0 0 80%;
			max-width: none;
			min-width: none;
		}
		.Or{
			display: none;
			font-size: 1.25em;
			padding-left:.5em;
		}
		.List{
			padding-left: 10px;
			font-size: 3.25em;
			color: var(--color__green-dark);
		}
			.List:hover{
				cursor: pointer;
				color: var(--color__green-dark3);
			}

	.PreDefinedListTrigger{
		margin-top: 1em;
		/*font-size: .9em;*/

	}




@media screen and (min-width: 576px){
	.Or{
		display: inherit;
	}
}

@media screen and (min-width: 768px){
	.AddIngWrapper,
	.ContentArea{
		min-width: 80%;
		max-width: 80%;
	}
}

@media screen and (min-width: 992px){
	.AddIngWrapper,
	.ContentArea{
		min-width: 60%;
		max-width: 60%;
	}
}

@media screen and (min-width: 1200px){
	.AddIngWrapper,
	.ContentArea{
		min-width: 80%;
		max-width: 80%;
	}
}