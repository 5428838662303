.Nav {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: relative;
}

.Nav:global > .menu{
	flex: 0 1 94%;
	/*display: none;*/
	justify-content: flex-end;
}

.Nav:global > .menu > a{
	padding: 0 .5rem;
	border-right: 1px solid var(--color__gray);
	text-decoration: none;
}

	.Nav:global > .menu > a:hover,
	.Nav:global > .menu > a.active{
		color: var(--color__green);
	}

@media screen and (min-width: 768px ) {
	.Nav:global > .menu{
		display: flex;
	}		
	
	.Nav:global > .menu > a{
		padding: 0 1rem;
	}

}