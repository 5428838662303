.Nav {
	z-index: 997;
	max-width: 200px;
	flex-direction: column;
	display: none;
	position: absolute;
	top: 30px;
	right: -1em;
	padding: 1.5rem .25rem;
	background-color: rgba(255, 255, 255, 0.9);
	box-shadow: 0 10px 10px -5px var(--color__gray);
}

/* greeting */
.Nav:global > .user-name{
	font-size: .95em;
    color: var(--color__gray);
    text-align: center;
    border-bottom: 1px solid var(--color__gray-light2);
    padding-bottom: .5em;
    margin-bottom: .25em;
}

.Nav:global > .menu{
	display: flex;
	flex-direction: column;
}

.Nav:global > .menu > a{
	padding: 0 1rem;
	line-height: 2.5;
	border-bottom: 1px solid var(--color__white-light);
	text-decoration: none;
}
	.Nav:global > .menu > a:hover{
		color: var(--color__green);
		border-bottom: 1px solid var(--color__white);
	}
	
	.Nav:global > .menu > a:last-child{
		border-bottom: 0;
	}

	.Nav:global > .menu > a > svg{
		color: var(--color__gray);
		margin-right: .5rem;
		vertical-align: middle;
	}
		.Nav:global > .menu > a:hover > svg{
			color: var(--color__gray-dark);
		}


/* login icon */
.LoginIcon{
	z-index: 998;
	margin-left: 1rem;
	cursor: pointer;
	white-space: nowrap;
}
	.LoginIcon:global > svg{
		position: relative; /* to apply z-index*/	
		z-index: 998;
		font-size: 1.65em;
    	line-height: 0;
    	vertical-align: middle;
    }

	.LoginIcon:global > svg:hover{
		color: var(--color__green);
	}
