.Aside{
	position: absolute; /* float over results */
	z-index:994;
	margin-top: 1em;
	
	position: sticky; /* make sticky*/
	top: 70px;
	display: inline-block;
	height: 0; /* keep content float up*/
}
	.FilterHide{
		width: 1px; 
		transition: width .2s ease-out .1s;
	}
	.FilterShow{
		width: auto;
	}

/* TOGGLE BTN */
.ToggleBtn{
	z-index: 995;
    right: -1em; /* position @ filter right*/
    top: 1.5em;
    position: absolute;
    font-size: 1em;
    background-color: var(--color__green-light3);
    padding: 1em;
    border-radius: 1em;
    box-shadow: 7px -1px 7px #5050571f;
}
	.ToggleBtn:hover{
		cursor: pointer;
		color: var(--color__green);
	}

/* FILTER WRAPPER */
.Wrapper{
	position: relative;	/* for icon */
	background-color: var(--color__green-light3); 
	padding: 2em 1em;
	border-radius: .75em;
    box-shadow: 10px 0 10px #5050571f;
	float: left;
	transition: all .3s ease-out .1s;
}
	/* SHOW */
	.FilterShow .Wrapper{
		opacity: 1;
		visibility: visible;
		width: 200px;	/* controls parnet width */
		left: 0; 
	}


	/* HIDE */
	.FilterHide .Wrapper{
		opacity: 0;
		visibility: hidden; 
		width: 0;
		left: -200px; 
	}
		.FilterShow .ToggleBtn{
		    right: -1em; 		/* toggle button placment*/
		}


/* FILTER GROUP */
.GroupWrapper{
	height: 70vh;
    overflow: auto;
    padding-right: 1em; /* space fore scrollbar */
}

.Group{
	position: relative;
	border: 0;
	margin: 0;
	padding: 0;
	border-bottom: 1px solid var(--color__white-light);
    padding-bottom: 1em;
	transition: all .2s ease-in .1s;
}
	/*disabled*/
	.Group:global:disabled label{
	}
	
	.Group:global:disabled label{
		color: var(--color__gray-light);
	}
	
	/* group title */
	.GroupTitle{
		font-family: var(--fontfamily__normal);
		color: var(--color__green-dark);
		font-size: .85em;
	}
	/* group label */
	.Label{
		font-size: .85em;
	}
	/* group checkbox */
	.Input{
		position: absolute;
		right: 0;
	}

	/* filter load indicator */
	.Group :global .filter-load-indicator{
		display:none;
		position: absolute;
	    right: 1.5em;
	    margin-top: 2.5px;
	    color: var(--color__green);;
	}
		.Group :global .filter-load-indicator.current-select{
			display:block;
		}
	

/* SINGLE group */
.SingleFilter{
	font-family: var(--fontfamily__normal);
	color: var(--color__green-dark);
	margin-bottom: 1em;
	font-weight: 600;
}


@media screen and (min-width: 576px){
	.Group :global .filter-load-indicator{
	   right: 1em;
	}
}

@media screen and (min-width: 992px){
	.Aside{
		position: relative; /* remove float over results */
		height: auto;
	}
	.GroupWrapper{
		height: auto;
	    overflow: inherit;
	}

	.ToggleBtn{
	    top: 3.5em;
	}

	.Wrapper{
		padding: 2em;
	}
}